import React from 'react'
import styled from 'styled-components'

// Libraries
import parse from 'html-react-parser'

const StyledBackgroundDescription = styled.div`
  color: ${props => props.theme.color.text.main};
  font-weight: ${props => props.theme.font.weight.s};
  right: 0;
  left: 0;
  top: 0;
  z-index: -1;
  user-select: none;

  & strong {
    font-weight: ${props => props.theme.font.weight.xl};
  }

  @media (min-width: 992px) {
    position: ${props => props.relative ? 'relative' : 'absolute'};
    font-size: 90px;
    line-height: 90px;
    opacity: .1;
  }

  @media (max-width: 991px) {
    font-size: 50px;
    line-height: 50px;
    opacity: .15;
  }
`

const BackgroundDescription = ({ content, className, relative }) => (
  <div className={`position-relative ${className}`}>
    <StyledBackgroundDescription relative={relative}>
      {parse(content)}
    </StyledBackgroundDescription>
  </div>
)

export default BackgroundDescription