import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

const Hero = styled.div`
  width: 100%;
  height: ${props => props.small ? 45 : 60}vw;
  max-width: ${props => props.big ? 2100 : 1550}px;
  margin: 0 auto;
  position: relative;

  ${props => props.custom && `
      max-width: 1550px;

      &:after {
        border-bottom-left-radius: 35px;
        border-bottom-right-radius: 35px;
      }
  `}

  ${props => props.overflow && `
    overflow: visible !important;
  `};

  ${props => props.rounded && `
    @media (min-width: 1550px) {
      border-bottom-left-radius: 35px;
      border-bottom-right-radius: 35px;
      overflow: hidden;
    }
  `};


${props => props.home && `
    @media (max-width: 991px){
      min-height: 650px !important;
      max-height: 650px !important;
    }
  `}
  

  @media (min-width: 1200px) {
    min-height: ${props => props.small ? 400 : 550}px;
    max-height: ${props => props.small ? 650 : 750}px;

  }

  @media (max-width: 1199px) {
    min-height: ${props => props.small ? 500 : 500}px;
    max-height: ${props => props.small ? 650 : 650}px;
  }


   @media (max-width: 991px) {
    min-height: ${props => props.big ? 700 : 300}px;
    max-height: ${props => props.big ? 700 : 300}px;
  }
`

const HeroImage = styled(Plaatjie)`
  position: absolute !important;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .1);
  z-index: 1;

  @media (min-width: 1550px) {
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
  }
`

const Content = styled.div`
  z-index: 2;
`

const HeroDefault = ({ image, children, small, big, custom, hideOverlay, overflow, home, rounded = true, className = '' }) => (
  <Hero small={small} custom={custom} big={big} rounded={rounded} overflow={overflow} home={home} className={className}>
    {!hideOverlay && (
      <Overlay />
    )}
    <HeroImage image={image} alt="" />
    <Content className="container position-relative w-100 h-100">
      {children}
    </Content>
  </Hero>
)

export default HeroDefault
